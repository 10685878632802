// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../assets/fonts/Poppins-Regular.ttf) format("truetype");
}

/*************** Ionic CSS Variables ***************/
//:root {
//  body {
//  --ion-color-primary: #006a56;
//  --ion-color-primary-rgb: 0,106,86;
//  --ion-color-primary-contrast: #d7d7d7;
//  --ion-color-primary-contrast-rgb: 215,215,215;
//  --ion-color-primary-shade: #00715a;
//  --ion-color-primary-tint: #1a8d75;
//
//  --ion-color-secondary: #24bc9e;
//  --ion-color-secondary-rgb: 36,188,158;
//  --ion-color-secondary-contrast: #000000;
//  --ion-color-secondary-contrast-rgb: 0,0,0;
//  --ion-color-secondary-shade: #20a58b;
//  --ion-color-secondary-tint: #3ac3a8;
//
//  --ion-color-tertiary: #02f2c2;
//  --ion-color-tertiary-rgb: 2,242,194;
//  --ion-color-tertiary-contrast: #000000;
//  --ion-color-tertiary-contrast-rgb: 0,0,0;
//  --ion-color-tertiary-shade: #02d5ab;
//  --ion-color-tertiary-tint: #1bf3c8;
//
//  --ion-color-success: #19cc61;
//  --ion-color-success-rgb: 25,204,97;
//  --ion-color-success-contrast: #000000;
//  --ion-color-success-contrast-rgb: 0,0,0;
//  --ion-color-success-shade: #16b455;
//  --ion-color-success-tint: #30d171;
//
//  --ion-color-warning: #ffb10a;
//  --ion-color-warning-rgb: 255,177,10;
//  --ion-color-warning-contrast: #000000;
//  --ion-color-warning-contrast-rgb: 0,0,0;
//  --ion-color-warning-shade: #e09c09;
//  --ion-color-warning-tint: #ffb923;
//
//  --ion-color-danger: #dd2c44;
//  --ion-color-danger-rgb: 221,44,68;
//  --ion-color-danger-contrast: #ffffff;
//  --ion-color-danger-contrast-rgb: 255,255,255;
//  --ion-color-danger-shade: #c2273c;
//  --ion-color-danger-tint: #e04157;
//
//  --ion-color-light: #f4f5f8;
//  --ion-color-light-rgb: 244,245,248;
//  --ion-color-light-contrast: #000000;
//  --ion-color-light-contrast-rgb: 0,0,0;
//  --ion-color-light-shade: #d7d8da;
//  --ion-color-light-tint: #f5f6f9;
//
//  --ion-color-medium: #989aa2;
//  --ion-color-medium-rgb: 152,154,162;
//  --ion-color-medium-contrast: #000000;
//  --ion-color-medium-contrast-rgb: 0,0,0;
//  --ion-color-medium-shade: #86888f;
//  --ion-color-medium-tint: #a2a4ab;
//
//  --ion-color-dark: #222428;
//  --ion-color-dark-rgb: 34,36,40;
//  --ion-color-dark-contrast: #ffffff;
//  --ion-color-dark-contrast-rgb: 255,255,255;
//  --ion-color-dark-shade: #1e2023;
//  --ion-color-dark-tint: #383a3e;
//}
//
//  .ios, .md {
//    body {
//      --ion-background-color: #003d31;
//      --ion-background-color-rgb: 0,61,49;
//
//      --ion-text-color: #b0b0b0;
//      --ion-text-color-rgb: 176,176,176;
//
//      --ion-font-family: 'Poppins', sans-serif;
//
//      --ion-color-step-50: #095b4a;
//      --ion-color-step-100: #126050;
//      --ion-color-step-150: #1a6455;
//      --ion-color-step-200: #23695a;
//      --ion-color-step-250: #2c6d60;
//      --ion-color-step-300: #357265;
//      --ion-color-step-350: #3e766a;
//      --ion-color-step-400: #467b70;
//      --ion-color-step-450: #4f7f75;
//      --ion-color-step-500: #58847b;
//      --ion-color-step-550: #618880;
//      --ion-color-step-600: #6a8c85;
//      --ion-color-step-650: #72918b;
//      --ion-color-step-700: #7b9590;
//      --ion-color-step-750: #849a95;
//      --ion-color-step-800: #8d9e9b;
//      --ion-color-step-850: #96a3a0;
//      --ion-color-step-900: #9ea7a5;
//      --ion-color-step-950: #a7acab;
//
//      --ion-item-background: #012720;
//      --ion-card-background: #012720;
//      --ion-toolbar-background: #012720;
//      --ion-tab-bar-background: #012720;
//    }
//  }
//
//  .ios, .md {
//    ion-modal {
//      --ion-background-color: var(--ion-color-step-100);
//      --ion-toolbar-background: var(--ion-color-step-150);
//      --ion-toolbar-border-color: var(--ion-color-step-250);
//    }
//  }
//}
//
//@media (prefers-color-scheme: dark) {
//  /***************  Dark Theme ***************/
//
//  body {
//    --ion-color-primary: #006a56;
//    --ion-color-primary-rgb: 0,106,86;
//    --ion-color-primary-contrast: #d7d7d7;
//    --ion-color-primary-contrast-rgb: 215,215,215;
//    --ion-color-primary-shade: #00715a;
//    --ion-color-primary-tint: #1a8d75;
//
//    --ion-color-secondary: #24bc9e;
//    --ion-color-secondary-rgb: 36,188,158;
//    --ion-color-secondary-contrast: #000000;
//    --ion-color-secondary-contrast-rgb: 0,0,0;
//    --ion-color-secondary-shade: #20a58b;
//    --ion-color-secondary-tint: #3ac3a8;
//
//    --ion-color-tertiary: #02f2c2;
//    --ion-color-tertiary-rgb: 2,242,194;
//    --ion-color-tertiary-contrast: #000000;
//    --ion-color-tertiary-contrast-rgb: 0,0,0;
//    --ion-color-tertiary-shade: #02d5ab;
//    --ion-color-tertiary-tint: #1bf3c8;
//
//    --ion-color-success: #19cc61;
//    --ion-color-success-rgb: 25,204,97;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #16b455;
//    --ion-color-success-tint: #30d171;
//
//    --ion-color-warning: #ffb10a;
//    --ion-color-warning-rgb: 255,177,10;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e09c09;
//    --ion-color-warning-tint: #ffb923;
//
//    --ion-color-danger: #dd2c44;
//    --ion-color-danger-rgb: 221,44,68;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #c2273c;
//    --ion-color-danger-tint: #e04157;
//
//    --ion-color-light: #f4f5f8;
//    --ion-color-light-rgb: 244,245,248;
//    --ion-color-light-contrast: #000000;
//    --ion-color-light-contrast-rgb: 0,0,0;
//    --ion-color-light-shade: #d7d8da;
//    --ion-color-light-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-dark: #222428;
//    --ion-color-dark-rgb: 34,36,40;
//    --ion-color-dark-contrast: #ffffff;
//    --ion-color-dark-contrast-rgb: 255,255,255;
//    --ion-color-dark-shade: #1e2023;
//    --ion-color-dark-tint: #383a3e;
//  }
//
//  .ios, .md {
//    body {
//      --ion-background-color: #003d31;
//      --ion-background-color-rgb: 0,61,49;
//
//      --ion-text-color: #b0b0b0;
//      --ion-text-color-rgb: 176,176,176;
//
//      --ion-font-family: 'Poppins', sans-serif;
//
//      --ion-color-step-50: #095b4a;
//      --ion-color-step-100: #126050;
//      --ion-color-step-150: #1a6455;
//      --ion-color-step-200: #23695a;
//      --ion-color-step-250: #2c6d60;
//      --ion-color-step-300: #357265;
//      --ion-color-step-350: #3e766a;
//      --ion-color-step-400: #467b70;
//      --ion-color-step-450: #4f7f75;
//      --ion-color-step-500: #58847b;
//      --ion-color-step-550: #618880;
//      --ion-color-step-600: #6a8c85;
//      --ion-color-step-650: #72918b;
//      --ion-color-step-700: #7b9590;
//      --ion-color-step-750: #849a95;
//      --ion-color-step-800: #8d9e9b;
//      --ion-color-step-850: #96a3a0;
//      --ion-color-step-900: #9ea7a5;
//      --ion-color-step-950: #a7acab;
//
//      --ion-item-background: #012720;
//      --ion-card-background: #012720;
//      --ion-toolbar-background: #012720;
//      --ion-tab-bar-background: #012720;
//    }
//  }
//
//  .ios, .md {
//    ion-modal {
//      --ion-background-color: var(--ion-color-step-100);
//      --ion-toolbar-background: var(--ion-color-step-150);
//      --ion-toolbar-border-color: var(--ion-color-step-250);
//    }
//  }
//}


:root {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #5260ff;
  --ion-color-secondary-rgb: 82,96,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #4854e0;
  --ion-color-secondary-tint: #6370ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}
